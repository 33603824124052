import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import PdfReports from 'components/PdfReports/PdfReports'
import moment from 'moment'
function ReportsBillingHistoryAdmin () {
  const [data, setData] = useState(null)
  const [pdfData, setPdfData] = useState(null)
  const location = useLocation()
  React.useEffect(() => {
    setData(location.state)
  }, [location])
  const columns = [
    { key: 'orderNumber', label: 'order Number' },
    { key: 'createdAt', label: 'Order Date' },
    { key: 'primaryFirstName', label: 'Name' },
    { key: 'total', label: 'Total' }
  ]
  React.useEffect(() => {
    if (data) {
      setPdfData(data.data.map((item) => {
        return {
          orderNumber: item.orderNumber,
          createdAt: moment(item.createdAt).format('Do MMMM YYYY, h:mm A'),
          primaryFirstName: item.customer.primaryFirstName + ' ' + item.customer.primaryLastName,
          total: item.total
        }
      }))
    }
  }, [data])
  return (
        <>
        {
          pdfData && <PdfReports
          columns={columns}
          content={pdfData}
          />
        }
        </>
  )
}
export default ReportsBillingHistoryAdmin
