import React, { useState, useEffect } from "react";
import { Col, Row, Modal } from "reactstrap";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import "./BulkUploadModal.scss";
import BulkSellPriceUpload from "../../../assets/sheets/Bulk Sell Price Upload.xlsx";
import BulkProductUpload from "../../../assets/sheets/Bulk Product Upload.xlsx";
import BulkInventoryUpload from "../../../assets/sheets/Bulk Inventory Upload.xlsx";
import toastr from "toastr";
import { GetData } from "apiHandler";
import Select from "react-select";

export default function BulkUploadModal(props) {
  const [modalShow, setModalShow] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(false);
  const [bulkError, setBulkError] = useState(false);
  const userObj = JSON.parse(localStorage.authUser);
  const [bulkPriceTemplate, setBulkPriceTemplate] = useState("");
  const [customerLocations, setCustomerLocations] = useState([])

  // handles the file uploading error and setting file to a file variable
  const handleChange = (e) => {
    const dropFile = e?.target?.files[0] || e[0];
    if (
      dropFile.name.split(".").pop() === "csv" ||
      dropFile.name.split(".").pop() === "xlsx"
    ) {
      setFile(dropFile);
      setError(false);
    } else {
      setError(true);
    }
  };

  // handle the modal opening and closing
  const handleModal = (e) => {
    e.preventDefault();
    setFile(null);
    setBulkError(false);
    setModalShow((prevState) => !prevState);
    if (props.modalType === "Bulk Price Upload") {
      props?.setSelectedCustomer(null);
      props.setSelectedLocationCsv(null)
    }
    if (props?.modalType === "Import Product Availability") {
      props?.setSuccessAvailability(null)
    }
    setBulkPriceTemplate("");
  };

  // removes the file from file variable on replace button
  const handleRemove = (e) => {
    e.preventDefault();
    setFile(null);
  };
  const handleUploadFile = (e) => {
    if (userObj.roleId === 3 || userObj.roleId === 4) {
      props.productsBulkUpload(e, file);
    } else {
      props.importSellingPrice(e, file);
    }
  };

  useEffect(() => {
    if (props.bulkError) {
      setBulkError(props.bulkError);
      setModalShow(true);
    } else {
      setModalShow(false);
      setFile(null);
    }
  }, [props.bulkError]);

  const handleDownload = (e, file) => {
    const a = document.createElement("a");
    a.href = "https://" + file;
    a.download = "download";
    a.click();
  };

  const getBulkPriceTemplate = (customerId, locationId) => {
    let url = `admin/bulkNationalPriceTemplate?customerId=${customerId}&customerLocationId=${locationId}`;
    GetData(url).then((response) => {
      if (response.status === true) {
        setBulkPriceTemplate(response?.result?.path);
      } else {
        toastr.error(response.message, "Error");
      }
    });
  };

  const getCustomerLocations = (location) => {
    GetData('admin/getCustomerLocations/' + location).then((response) => {
      if (response.status === true) {
        const transformedRes = response.result.map((location) => ({
          label: location.locationName,
          value: location.id,
        }));
      
        setCustomerLocations(transformedRes);
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleCustomer = (selectedOption) => {
    if (selectedOption) {
      props.setSelectedLocationCsv(null)
      setCustomerLocations([])
      setBulkPriceTemplate("")
      const customerId = selectedOption.value;
      props.setSelectedCustomer(customerId);
      getCustomerLocations(customerId)
    }
  };

  const handleLocation = (selectedOption) => {
    if (selectedOption) {
      const locationId = selectedOption.value;
      props.setSelectedLocationCsv(locationId);
      getBulkPriceTemplate(props.selectedCustomer, locationId);
    }
  };
  // ----------------Sample file download for discontinued products--------------
  const handleSampleAvailabilityDownload = () => {
    let url = "admin/bulkDiscountinuedTemplate";
    GetData(url).then((response) => {
      if (response.status === true) {
        const a = document.createElement("a");
        a.href = "https://" + response?.result?.path;
        a.download = "download";
        a.click();
      } else {
        toastr.error(response.message, "Error");
      }
    });
  };
  
  return (
    <>
      <div>
        <Row>
          <Col lg={12}>
            <button className="bulk-upload-btn" onClick={handleModal}>
              {props.modalType}
            </button>
            <Modal size="md" isOpen={modalShow} toggle={handleModal} centered>
              <div className="modal-header ">
                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  <b>{props.modalType} by XLSX</b>
                </h5>
                <button
                  onClick={handleModal}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <hr className="m-0"></hr>
              <div className="modal-body bulk-upload-modal-container">
                {props.modalType === "Import Product Availability" && (
                  <div className="mb-2" style={{ width: "50%" }}>
                    <p>Please select availability type</p> {" "}
                    <input
                      type="radio"
                      id="markAvailable"
                      name="availability"
                      value={1}
                      onChange={(e) =>
                        props.setAvailabilityType(e.target.value)
                      }
                    />
                      <label for="markAvailable">Mark as available</label>
                    <br /> {" "}
                    <input
                      type="radio"
                      id="markUnavailable"
                      name="availability"
                      value={0}
                      onChange={(e) =>
                        props.setAvailabilityType(e.target.value)
                      }
                    />
                      <label for="markUnavailable">Mark as unavailable</label>
                  </div>
                )}
                <div className="d-flex gap-3 align-items-center">
                  {props.modalType === "Bulk Price Upload" && (
                    <div className="mb-2" style={{ width: "50%" }}>
                      <Select
                        // value={props.selectedCustomer}
                        value={props.data.find(
                          (option) => option.value === props.selectedCustomer
                        )}
                        onChange={handleCustomer}
                        options={props.data}
                        placeholder="Select Customer"
                        isSearchable
                        className='mb-2'
                      />

                      <Select
                        value={props.selectedLocationCsv ? customerLocations.find(
                          (option) => option.value === props.selectedLocationCsv
                        ) : null}
                        onChange={handleLocation}
                        options={customerLocations}
                        placeholder="Select Location"
                        isSearchable
                        isDisabled={customerLocations.length === 0}
                      />
                    </div>
                  )}
                  <p className="download-csv-heading">
                    {props.modalType === "Bulk Price Upload" ? (
                      bulkPriceTemplate?.length > 0 && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={(e) => handleDownload(e, bulkPriceTemplate)}
                        >
                          Download &quot; XLSX Template &quot;
                        </span>
                      )
                    ) : props.modalType === "Import Product Availability" ? (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={handleSampleAvailabilityDownload}
                      >
                        Download &quot; XLSX Template &quot;
                      </span>
                    ) : (
                      <a
                        className="download-csv-heading"
                        href={
                          props.sheet === "BulkInventory"
                            ? BulkInventoryUpload
                            : props.modalType === "Import Products Detail"
                            ? BulkSellPriceUpload
                            : props.modalType === "Bulk Price Upload"
                            ? bulkPriceTemplate
                            : BulkProductUpload
                        }
                        rel="noreferrer"
                        download
                        target="_blank"
                      >
                        Download &quot; XLSX Template &quot;
                      </a>
                    )}
                  </p>
                </div>
                {error && (
                  <>
                    <div className="d-flex error-warning-container">
                      <i className="bx bxs-info-circle me-2"></i>
                      <div>
                        <p className="m-0">
                          There was an error importing your XLSX file. After you
                          fix the error, try importing the XLSX file again.
                        </p>
                        <p className="m-0">
                          - Invalid XLSX Header: Missing headers: Title
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {props?.successBulkCount > 0 && (
                  <div
                    className="error-warning-container mb-2"
                    style={{ background: "#e6f8e6" }}
                  >
                    <p className="m-0">
                      {props?.successBulkCount} parts prices have been
                      successfully updated.
                    </p>
                  </div>
                )}
                {props?.successAvailability > 0 && (
                  <div
                    className="error-warning-container mb-2"
                    style={{ background: "#e6f8e6" }}
                  >
                    <p className="m-0">
                      {`${props?.successAvailability} ${
                        props?.successAvailability === 1
                          ? "product"
                          : "products"
                      } availability have been
                      successfully updated.`}
                    </p>
                  </div>
                )}
                {bulkError !== "" && bulkError !== false && (
                  <div className="d-flex error-warning-container">
                    <i className="bx bxs-info-circle me-2"></i>
                    <div>
                      {props.modalType === "Bulk Price Upload" ? (
                        <>
                          {bulkError?.invalidPartNumber !== 0 && (
                            <li>
                              {bulkError?.invalidPartNumber} rows contain
                              invalid part numbers.
                            </li>
                          )}{" "}
                          {bulkError?.invalidPrice !== 0 && (
                            <li>
                              {bulkError?.invalidPrice} rows contain invalid
                              prices.
                            </li>
                          )}
                        </>
                      ) : props.modalType === "Import Product Availability" ? (
                        <>
                          {bulkError?.invalidBrand !== 0 && (
                            <li>
                              {bulkError?.invalidBrand} rows contain
                              invalid brand
                            </li>
                          )}{" "}
                          {bulkError?.invalidPartNumber !== 0 && (
                            <li>
                              {bulkError?.invalidPartNumber} rows contain invalid
                              part number.
                            </li>
                          )}
                        </>
                      ) : (
                        <p
                          className="m-0"
                          dangerouslySetInnerHTML={{ __html: bulkError }}
                        ></p>
                      )}
                      {props.bulkErrorSheet !== "" && (
                        <button
                          className="replace-btn mt-2"
                          onClick={(e) =>
                            handleDownload(e, props.bulkErrorSheet)
                          }
                        >
                          Download Sheet
                        </button>
                      )}
                    </div>
                  </div>
                )}
                <div className="my-2">
                  {file ? (
                    <>
                      <p>Added File :</p>
                      <hr className="m-0"></hr>{" "}
                      <p className="my-2 d-flex justify-content-between align-items-center">
                        <span className="d-flex align-items-center">
                          <i className="bx bx-file me-2"></i>
                          {file.name}
                        </span>
                        <button className="replace-btn" onClick={handleRemove}>
                          Replace File
                        </button>
                      </p>
                      <hr className="m-0"></hr>
                    </>
                  ) : (
                    <Dropzone onDrop={handleChange}>
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone drop-zone-container-left ">
                          <div
                            className="dz-message needsclick mt-2 p-0"
                            {...getRootProps()}
                          >
                            <input
                              {...getInputProps()}
                              onChange={handleChange}
                              name="postImage"
                            />
                            <div className="">
                              <i className="display-4 text-muted bx bxs-cloud-upload drop-zone-icon" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                </div>
                {props?.modalType !== "Bulk Price Upload" && (
                  <>
                    <p className="mb-0">
                      <b>Upload XLSX Steps :</b>
                    </p>
                    <ol>
                      <li>Go to Products Page.</li>
                      <li>
                        Click <b>{props.modalType}</b>.
                      </li>
                      <li>
                        Choose your <b>updated XLSX file</b>.
                      </li>
                      <li>
                        Click <b>Upload & Continue</b>
                      </li>
                    </ol>
                  </>
                )}
              </div>
              <hr className="m-0"></hr>
              <div className="modal-body bulk-upload-modal-container">
                <div className="text-end">
                  <button className="cancel-btn me-3" onClick={handleModal}>
                    Cancel
                  </button>
                  {props.modalType === "Bulk Price Upload" ? (
                    <button
                      className={`upload-btn ${
                        file && props.selectedCustomer && "upload-btn-active"
                      } `}
                      disabled={!(file && props.selectedCustomer)}
                      onClick={handleUploadFile}
                    >
                      Upload
                    </button>
                  ) : props.modalType === "Import Product Availability" ? (
                    <button
                      className={`upload-btn ${
                        file && props.availabilityType && "upload-btn-active"
                      } `}
                      disabled={!file && props.availabilityType}
                      onClick={
                        file && props.availabilityType
                          ? handleUploadFile
                          : undefined
                      }
                    >
                      Upload
                    </button>
                  ) : (
                    <button
                      className={`upload-btn ${file && "upload-btn-active"} `}
                      disabled={!file}
                      onClick={handleUploadFile}
                    >
                      Upload
                    </button>
                  )}
                </div>
              </div>
            </Modal>
          </Col>
        </Row>
      </div>
    </>
  );
}

BulkUploadModal.propTypes = {
  data: PropTypes.array,
  modalType: PropTypes.string,
  importSellingPrice: PropTypes.func,
  productsBulkUpload: PropTypes.func,
  bulkError: PropTypes.any,
  bulkErrorSheet: PropTypes.any,
  sheet: PropTypes.string,
};
