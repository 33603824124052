/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import { GetData } from 'apiHandler'

// Import images
import avatar3 from '../../../assets/images/users/avatar-3.jpg'
import avatar4 from '../../../assets/images/users/avatar-4.jpg'

// i18n

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [notifications, setNotifications] = useState([])
  const userObj = JSON.parse(localStorage.authUser)

  useEffect(() => {
    if (!(userObj.roleId === 3 || userObj.roleId === 4)) {
      getNotifications(1)
    }
  }, [])

  const getNotifications = (p = 1) => {
    const pageSize = 5
    const url = 'admin/getWdNotifications?page=' + p + '&pageSize=' + pageSize
    GetData(url)
      .then((response) => {
        if (response.status && response.code === 200) {
          setNotifications(response.result.notifications)
          setNotifications(response.result.notifications.map((notification) => {
            const notifyMessage = notification.errormessage ? notification.errormessage : notification.message
            return {
              msg: notifyMessage,
              ...notification
            }
          }))
        }
      })
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {/* <span className="badge bg-danger rounded-pill">3</span> */}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {'Notifications'} </h6>
              </Col>
              <div className="col-auto">
                <a href="/notifications" className="small">
                  {' '}
                  View All
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: '230px' }}>
            {
              notifications.length > 0 && notifications.map((notification, key) => {
                return (
                  <Link to="/notifications" key={key} className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16" style={{ height: '35px', width: '35px' }}>
                    <i className="bx bx-cart" />
                  </span>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">
                    {
                      notification.msg
                    }
                  </h6>
                  {/* <div className="font-size-12 text-muted">
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />{' '}
                      {('3 min ago')}{' '}
                    </p>
                  </div> */}
                </div>
              </div>
            </Link>
                )
              })
            }
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="/notifications">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{('View More..')}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default NotificationDropdown

NotificationDropdown.propTypes = {
  t: PropTypes.any
}
