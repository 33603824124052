import React, { useState, useEffect } from 'react'
import { Document, Page, View, Text, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import mainLogo from '../../../src/assets/images/pdf.png'
// Create styles
const styles = StyleSheet.create({
  page: {
    padding: '30px'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowTop: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowTopText: {
    border: '1px solid #000'
  },
  // So Declarative and unDRY 👌
  rowTop1: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop2: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop3: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop4: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  rowTop5: {
    width: '20%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  row1: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row2: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    wordBreak: 'break-word',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row3: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    wordBreak: 'break-word',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row4: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: '300',
    border: '1px solid #000'
  },
  row5: {
    width: '20%',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: '300',
    border: '1px solid #000',
    display: 'flex',
    flexDirection: 'column'
  },
  uom: {
    fontSize: '8px',
    color: '#202020',
    display: 'block'
  },
  image: {
    width: '150px',
    marginBottom: '25px'
  },
  rowTop8: {
    width: '33%',
    border: '1px solid #000',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '700'
  },
  row8: {
    width: '33%',
    padding: '5px 10px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: '300',
    border: '1px solid #000'
  }
})
function InvoicingDetailspdf () {
  const [data, setData] = useState()
  const [currentRole, setCurrentRole] = useState()
  const location = useLocation()
  React.useEffect(() => {
    setData(location.state)
  }, [location])
  //   console.log(location.state)
  //   const flag = data?.value
  useEffect(() => {
    if (localStorage.authUser) {
      const userObj = JSON.parse(localStorage.authUser)
      setCurrentRole(userObj.roleId)
    }
  }, [])
  return (
        <>
        <PDFViewer>
            <Document>
            { currentRole === 3
              ? <Page size="A4" style={styles.page}>
                <Image src={mainLogo} style={styles.image} />
      <View style={styles.rowTop}>
      <Text style={styles.rowTop1}>Order Number</Text>
        <Text style={styles.rowTop2}>Name</Text>
        <Text style={styles.rowTop3}>Location</Text>
        {/* <Text style={styles.rowTop4}>Order Date</Text> */}
        <Text style={styles.rowTop4}>Order Date</Text>
        <Text style={styles.rowTop5}>
           Total
        </Text>
      </View>
      {
        data?.data.map((item, i) => {
          return (
                <View style={styles.row} wrap={false} key={i}>
          <Text style={styles.row1}>
          {item.parentOrder.orderNumber}
          </Text>
          <Text style={styles.row2}>{item.parentOrder.shippingFirstName} {' '}{item.parentOrder.shippingLastName}</Text>
          <Text style={styles.row3}>
          {item.parentOrder.shippingCity}{' '} {item.parentOrder.shippingState}</Text>
          <Text style={styles.row4}>
          {moment(item.createdAt).format('Do MMMM YYYY, h:mm A')}
          </Text>
          <Text style={styles.row5}>
          {item.total}
          </Text>
        </View>
          )
        })
      }
      </Page>
              : <Page size="A4" style={styles.page}>
                <Image src={mainLogo} style={styles.image} />
      <View style={styles.rowTop}>
      <Text style={styles.rowTop8}>Order Number</Text>
        <Text style={styles.rowTop8}>Order Date</Text>
        <Text style={styles.rowTop8}>Order Value</Text>
        {/* <Text style={styles.rowTop4}>Order Date</Text> */}
        {/* <Text style={styles.rowTop4}>Order Date</Text>
        <Text style={styles.rowTop5}>
           Total
        </Text> */}
      </View>
      {
        data?.data.map((item, i) => {
          return (
                <View style={styles.row} wrap={false} key={i}>
          <Text style={styles.row8}>
          {item.orderNumber}
          </Text>
          <Text style={styles.row8}>{moment(item.orderDate).format('Do MMMM YYYY, h:mm A')}</Text>
          <Text style={styles.row8}>
          {item.orderValue}</Text>
          {/* <Text style={styles.row4}>
          {moment(item.createdAt).format('Do MMMM YYYY, h:mm A')}
          </Text> */}
          {/* <Text style={styles.row5}>
          {item.total}
          </Text> */}
        </View>
          )
        })
      }
      </Page>
    }
    </Document>
        </PDFViewer>
        </>
  )
}
export default InvoicingDetailspdf
