/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Loader from '../../components/Loader'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Collapse,
  Table,
  Modal
} from 'reactstrap'
import toastr from 'toastr'
import TableComponent from 'components/Table'
import 'toastr/build/toastr.min.css'
import { GetData } from 'apiHandler'
import { useHistory } from 'react-router-dom'

let searchTimer
let filterByKeyObject = { key: 'all' }

const Notifications = () => {
  const history = useHistory()
  const [loader, setLoader] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [selectedData, setSelectedData] = useState({})
  const [singlebtn, setSinglebtn] = useState(false)
  const userObj = JSON.parse(localStorage.authUser)
  const [notifications, setNotifications] = useState([])
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const [openCollpase, setOpenCollapse] = useState([])
  const filterTypes = ['All', 'Part Number', 'Brand', 'Category', 'Sub Category']
  let searchTimer

  useEffect(() => {
    if (notifications.length > 0) {
      // eslint-disable-next-line array-callback-return
      notifications.map((data) => {
        setOpenCollapse((prevState) => ([
          ...prevState,
          false
        ]))
      })
    }
  }, [notifications])

  // useEffect(() => {
  //   if (userObj.roleId === 3 || userObj.roleId === 2) {
  //     history.push('/dashboard')
  //   }
  // }, [])

  const [columns, setColumns] = useState([
    { key: 'msg', label: 'Message' }
  ])

  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })

  const [selectedFilter, setSelectedFilter] = useState({
    value: 'all',
    key: 'All'
  })

  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })

  const paginationHandler = (page) => {
    getNotifications(page)
  }

  const dropToggle = () => {
    setSinglebtn(!singlebtn)
  }

  useEffect(() => {
    getNotifications(1)
    return () => {
      filterByKeyObject = { key: 'all' }
    }
  }, [])

  const filterByKey = (obj) => {
    filterByKeyObject.key = obj.value
    setSelectedFilter(obj)
    if (filterByKeyObject.string) {
      getNotifications(1)
    }
  }

  const searchByText = (t) => {
    setSearchText(t)
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      filterByKeyObject.string = t
      getNotifications(1)
    }, 2000)
  }

  const getNotifications = (p = 1) => {
    setLoader(true)
    const pageSize = 20
    let url = 'admin/getWdNotifications?page=' + p + '&pageSize=' + pageSize

    if (filterByKeyObject.string) {
      url += '&search=' +
        filterByKeyObject.string
    }

    GetData(url)
      .then((response) => {
        if (response.status && response.code === 200) {
          setLoader(false)
          setNotifications(response.result.notifications)
          setNotifications(response.result.notifications.map((notification) => {
            const notifyMessage = notification.errormessage ? notification.errormessage : notification.message
            return {
              msg: notifyMessage,
              ...notification
            }
          }))
          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: response.result.total
          })
        } else {
          setLoader(false)
          toastr.error(response.message, 'Error')
        }
      }).catch((err) => {
        setLoader(false)
        toastr.error(err, 'Error')
      })
  }

  const handleRow = (data) => {
    setSelectedData(data)
    setIsDisableOpen(true)
  }

  return (
        <>
        <Loader show={loader} />
        <div className="page-content">
        <Container fluid={true} className="customer-container">
        <div className="products-nav">
            <span className="products-heading">Notifications</span>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <div className="warehouse-navbar justify-content-end mb-2">
                    <div className="d-flex">
                      <div className="customer-search-form">
                        <input
                          className="form-control input-border customer-input-border"
                          type="text"
                          placeholder="Search by Message"
                          onChange={(e) => searchByText(e.target.value)}
                          value={searchText}
                        />
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <hr className=" mt-0 mb-4 tab-hr"></hr> */}
                  <div className='order-table'>
                  <TableComponent
                    columns={columns}
                    content={notifications}
                    paging={paging}
                    paginationHandler={paginationHandler}
                    arrowState={sortingState}
                    onClick={handleRow}
                  />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
                  className="modal-main-body"
                  isOpen={isDisableOpen}
                  toggle={() => setIsDisableOpen(false)}
                  centered
                >
                  <div className="modal-header">
                    <button
                      type="button"
                      onClick={() => {
                        setIsDisableOpen(false)
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-detail-div">
                      <p className="modal-details">Notification Details</p>
                      <p className="modal-warehouse-name mt-2">
                        <span className="warehouse-heading"> Part Number: </span>{' '}
                        {selectedData?.partNumber ? selectedData?.partNumber : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Message: </span>{' '}
                        {selectedData?.msg ? selectedData?.msg : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Vendor Name: </span>{' '}
                        {selectedData?.vendorDetail?.firstName ? selectedData?.vendorDetail?.firstName + ' ' + selectedData?.vendorDetail?.lastName : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Vendor Email: </span>{' '}
                        {selectedData?.vendorDetail?.email ? selectedData?.vendorDetail?.email : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Brand: </span>{' '}
                        {selectedData?.brand && selectedData?.brand.length > 0 ? selectedData.brand[0].BrandName : '--'}
                      </p>
                    </div>
                  </div>
                </Modal>
        </div>
        </>
  )
}

export default Notifications
