/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Label,
  Input,
  Button
} from 'reactstrap'
import './AccountPricing.scss'
import Select from 'react-select'
import { PostData, GetData, PutData, DeleteData } from 'apiHandler'
import toastr from 'toastr'
import Loader from '../../components/Loader'
import { Pagination } from '@mui/material'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import delIcon from '../../assets/images/ic_delete.svg'
import BulkUploadModal from 'components/Common/BulkUploadModal/BulkUploadModal'

const recordPerPage = 50
const AccountPricing = () => {
  const selectCustomerRef = useRef(null);
  const selectLocationRef = useRef(null);

  const [customers, setCustomers] = useState([])
  const [customerPopup, setCustomerPopup] = useState(false)
  const [deletePopup, setDeletePopup] = useState(false)
  const [parts, setParts] = useState([])
  const [loader, setLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [paging, setPaging] = useState({ totalRecord: 0, recordPerPage: recordPerPage })
  const [pagingCustomer, setPagingCustomer] = useState({
    currentPage: 1,
    recordPerPage: 50,
    totalRecord: 0
  })
  const [pagingParts, setPagingParts] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [selectIndex, setSelectIndex] = useState(null)
  const [mappingList, setMappingList] = useState([])
  const [mappingForm, setMappingForm] = useState({})
  const [partValue, setPartValue] = useState('')
  const [customerValue, setCustomerValue] = useState('')
  const [mappingFormError, setMappingFormError] = useState({})
  const [priceId, setPriceId] = useState('')
  const [tableMessage, setTableMessage] = useState('Please wait loading...')
  const [bulkError, setBulkError] = useState()
  const [bulkErrorSheet, setBulkErrorSheet] = useState('')
  const [successBulkCount, setSuccessBulkCount] = useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [filterByCustomer, setFilterByCustomer] = useState(null)
  const [customerLocations, setCustomerLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [selectedLocationCsv, setSelectedLocationCsv] = useState(null)


  useEffect(() => {
    getCustomerList()
    // getPartsList(1)
    if (selectCustomerRef.current) {
      selectCustomerRef.current.select.focus();
    }
  }, [])

  useEffect(() => {
    if (customerLocations.length > 0 && selectLocationRef.current) {
      selectLocationRef.current.select.focus();
    }
  }, [customerLocations]);

  useEffect(() => {
    getPartsList(pagingParts.currentPage)
  }, [pagingParts.currentPage, partValue])

  useEffect(() => {
    getMappingList()
  }, [currentPage, filterByCustomer, selectedLocation])

  useEffect(() => {
    setMappingForm({})
    setMappingFormError({})
  }, [customerPopup])

  useEffect(() => {
    if (selectIndex) {
      getCustomerList()
    }
  }, [selectIndex])

  const getMappingList = () => {
    let data = {
      page: currentPage,
      pageSize: recordPerPage,
      customerId: filterByCustomer,
      customerLocationId: selectedLocation,
    }
    setLoader(true)
    PostData('admin/getMappedPriceLevelParts', data).then((response) => {
      setLoader(false)
      if (response.status === true) {
        if (response.result.productsWithMappedValue.length) {
          setMappingList(response.result.productsWithMappedValue)
        } else {
          setMappingList(response.result.productsWithMappedValue)
          setTableMessage('Data not available')
        }
        setPaging({ totalRecord: response.result.total, recordPerPage: recordPerPage })
      } else {
        setTableMessage('Data not available')
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getCustomerList = () => {
    setLoader(true)
    const pageSize = 20
    let url = 'admin/getNationalCustomer?page=' + pagingCustomer.currentPage + '&pageSize=' + pageSize
    if (selectIndex) {
      url += '&part_id=' + mappingList[selectIndex]?.partId
    }
    if (mappingForm.part !== undefined) {
      url += '&part_id=' + mappingForm.part.partId
    }
    if (customerValue) {
      url +=
        '&search=' + customerValue
    }
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status === true) {
        const customersTemp = []
        response.result.customersData.map((customer) => {
          // customersTemp.push({ label: customer.primaryFirstName + ' ' + customer.primaryLastName, value: customer.customerId })
          customersTemp.push({ label: customer?.companyName, value: customer.customerId })
          return null
        })
        setCustomers(customersTemp)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  // ------------function to get customer locations---------------
  const getCustomerLocations = (location) => {
    GetData('admin/getCustomerLocations/' + location).then((response) => {
      if (response.status === true) {
        const transformedRes = response.result.map((location) => ({
          label: location.locationName,
          value: location.id,
        }));
      
        setCustomerLocations(transformedRes);
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  useEffect(() => {
    if (filterByCustomer || mappingForm?.customer?.value) {
    getCustomerLocations(filterByCustomer || mappingForm?.customer?.value)
    }
  },[filterByCustomer, mappingForm?.customer?.value])
  
  const accountID = (k, myID) => {
    setPriceId(myID.id)
    setDeletePopup(true)
  }
  function priceDelete() {
    DeleteData(`admin/deleteMappedPriceLevelParts/${priceId}`).then((response) => {
      setLoader(true)
      if (response.status === true) {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        setDeletePopup(false)
        getMappingList()
      } else {
        setLoader(false)
      }
    })
  }
  const getPartsList = (p) => {
    setLoader(true)
    const pageSize = 20
    let url = 'admin/getProductList?' +
      'page=' +
      p +
      '&pageSize=' +
      pageSize
    if (partValue) {
      url +=
        '&search=' + partValue +
        '&filterBy=partnumber'
    }
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status === true) {
        const partsTemp = [...parts]
        response.result.products.map((product) => {
          partsTemp.push({ label: product.partNumber, partNumber: product.partNumber, partId: product.part_id, brandId: product.BrandID, brand: product.brand })
          return null
        })
        setParts(partsTemp)
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleSelectCustomer = (e, key) => {
    if (key === -1) {
      setCustomerLocations([])
      setMappingForm(prev => ({ ...prev, customerLocationId: {} }))
      setMappingFormError(prev => ({ ...prev, customer: '' }))
      setMappingForm(prev => ({ ...prev, customer: e }))
    } else {
      const mappingListTemp = [...mappingList]
      mappingListTemp[key].customerId = e.value
      mappingListTemp[key].customerName = e.label
      mappingListTemp[key].update = true

      setMappingList(mappingListTemp)
    }
  }

  const handleSelectLocation = (e, key) => {
    if (key === -1) {
      setMappingFormError(prev => ({ ...prev, customerLocationId: '' }))
      setMappingForm(prev => ({ ...prev, customerLocationId: e }))
    } else {
      const mappingListTemp = [...mappingList]
      mappingListTemp[key].customerLocationId = e.value
      mappingListTemp[key].customerLocationName = e.label
      mappingListTemp[key].update = true

      setMappingList(mappingListTemp)
    }
  }

  const handleSelectPart = (e, key) => {
    if (key === -1) {
      setMappingFormError(prev => ({ ...prev, part: '' }))
      setMappingForm(prev => ({ ...prev, part: e }))
    } else {
      const mappingListTemp = [...mappingList]
      mappingListTemp[key].partNumber = e.partNumber
      mappingListTemp[key].BrandID = e.brandId
      mappingListTemp[key].update = true

      setMappingList(mappingListTemp)
    }
  }

  const handleInputPrice = (e, key, list) => {
    if (isNaN(e.target.value)) { return }
    if (key === -1) {
      setMappingFormError(prev => ({ ...prev, price: '' }))
      setMappingForm(prev => ({ ...prev, price: e.target.value }))
    } else {
      const mappingListTemp = [...mappingList]
      mappingListTemp[key].value = e.target.value
      mappingListTemp[key].BrandID = list.BrandID
      mappingListTemp[key].update = true

      setMappingList(mappingListTemp)
    }
  }
  const updateMapping = (key, check) => {
    const mappingListTemp = [...mappingList]
    if (check === true) {
      const data = {
        customerId: mappingListTemp[key].customerId,
        value: mappingListTemp[key].value,
        partNumber: mappingListTemp[key].partNumber,
        BrandID: mappingListTemp[key].BrandID, 
        updateRequired: 1,
        id: mappingListTemp[key].id,
      }
      setLoader(true)
      PutData('admin/updateNationalPriceForCustomer', data).then((response) => {
        setLoader(false)
        if (response.status === true) {
          mappingListTemp[key].update = false
          setMappingList(mappingListTemp)
          getMappingList()
          toastr.success(response.message, 'Success')
        } else {
          toastr.error(response.message, 'Error')
        }
      })
    } else {
      setMappingList(mappingListTemp)
    }
  }
  const addAccountPricing = () => {
    let error = 0
    if (!mappingForm.part) { error = 1; setMappingFormError(prev => ({ ...prev, part: 'Please select part' })) }
    if (!mappingForm.customer) { error = 1; setMappingFormError(prev => ({ ...prev, customer: 'Please select customer' })) }
    if (!mappingForm.customerLocationId) { error = 1; setMappingFormError(prev => ({ ...prev, customerLocationId: 'Please select customer location' })) }
    if (!mappingForm.price) { error = 1; setMappingFormError(prev => ({ ...prev, price: 'Please enter price' })) }
    if (mappingForm.price === 0) { error = 1; setMappingFormError(prev => ({ ...prev, price: 'Please enter price more than 0' })) }

    if (!error) {

      const data = {
        customerId: mappingForm.customer.value,
        customerLocationId: mappingForm.customerLocationId.value,
        value: mappingForm.price,
        partNumber: mappingForm.part.partNumber,
        BrandID: mappingForm.part.brandId,
        updateRequired: 0,
      }
      setLoader(true)
      PutData('admin/updateNationalPriceForCustomer', data).then((response) => {
        setLoader(false)
        if (response.status === true) {
          setCustomerPopup(false)
          getMappingList()
          toastr.success(response.message, 'Success')
        } else {
          toastr.error(response.message, 'Error')
        }
      })
    }
  }

  function handlePage(e, value) {
    e.preventDefault()
    setCurrentPage(value)
  }

  // -----------------------------Function for Bulk Price Upload----------------------
  const priceBulkUpload = (e, file) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('document', file)
    formData.append('customerId', selectedCustomer)
    formData.append('customerLocationId', selectedLocationCsv)
    setLoader(true)
    PostData('admin/bulkNationalPriceUpload', formData, true).then((response) => {
      if (response.status === true) {
        setLoader(false)
        setBulkError(false)
        getMappingList()
        toastr.success(response.message, 'Success')
      } else {
        getMappingList()
        setBulkError(response?.result?.errorsPartsInfo)
        setBulkErrorSheet(response?.result?.path)
        setSuccessBulkCount(response?.result?.successCount)
        // toastr.error(response?.message, 'Error')
        setLoader(false)
      }
    })
  }

  const customStyles = {
    clearIndicator: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
    }),
  }

  const handleClearFilter = () => {
    setSelectedLocation(null)
    setCustomerLocations([])
    setFilterByCustomer(null)
  }

  return (
    <div className="page-content">
      <Loader show={loader} />
      <Container fluid={true} className="customer-container">
        <div className="breadcrumb-main-nav">
          <Breadcrumbs title="UI Elements" breadcrumbItem="Account Pricing" />
          {/* <div style={{ width: '25%' }}>
      <Select
        ref={selectCustomerRef}
        value={customers.find((option) => option.value === filterByCustomer)}
        onChange={(selected) => {
          setSelectedLocation(null)
          setCustomerLocations([])
          setFilterByCustomer(selected ? selected.value : null)
        }}
        options={customers}
        placeholder="Select Customer"
        isSearchable
        isClearable
        styles={customStyles}
      />
    </div>
    <div style={{ width: '25%' }}>
      <Select
        ref={selectLocationRef}
        value={selectedLocation ? customerLocations.find((option) => option.value === selectedLocation) : null}
        onChange={(selected) => setSelectedLocation(selected ? selected.value : null)}
        options={customerLocations}
        placeholder="Select Locations"
        isSearchable
        isClearable
        styles={customStyles}
        isDisabled={customerLocations.length === 0}
      />
    </div> */}
          <div className="imp-exp-div d-flex gap-2">
            <BulkUploadModal modalType='Bulk Price Upload' importSellingPrice={priceBulkUpload} bulkError={bulkError} bulkErrorSheet={bulkErrorSheet} data={customers} location = {customerLocations} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} successBulkCount={successBulkCount} selectedLocationCsv={selectedLocationCsv} setSelectedLocationCsv={setSelectedLocationCsv} />
            <button className="add-new-customer-btn" onClick={() => setCustomerPopup(true)}>
              + Add New
            </button>
          </div>
        </div>
        <Row className="mt-3 d-flex align-items-center">
          <Col lg={4}>
          <Select
        ref={selectCustomerRef}
        value={filterByCustomer ? customers.find((option) => option.value === filterByCustomer) : null}
        onChange={(selected) => {
          setSelectedLocation(null)
          setCustomerLocations([])
          setFilterByCustomer(selected ? selected.value : null)
        }}
        options={customers}
        placeholder="Select Customer"
        isSearchable
        isClearable
        styles={customStyles}
      />
          </Col>
          <Col lg={4}>
          <Select
        ref={selectLocationRef}
        value={selectedLocation ? customerLocations.find((option) => option.value === selectedLocation) : null}
        onChange={(selected) => setSelectedLocation(selected ? selected.value : null)}
        options={customerLocations}
        placeholder="Select Locations"
        isSearchable
        isClearable
        styles={customStyles}
        isDisabled={customerLocations.length === 0}
      />
          </Col>
          <Col lg={2}>
          <Button
          color="danger"
          size="sm"
          onClick={handleClearFilter}
        >
          Clear
        </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={3} className='heading-tab'>
                    <p>Part Number</p>
                  </Col>
                  <Col lg={2} className='heading-tab'>
                    <p>Customer Name</p>
                  </Col>
                  <Col lg={2} className='heading-tab'>
                    <p>Customer Location</p>
                  </Col>
                  <Col lg={2} className='heading-tab'>
                    <p>Selling Price</p>
                  </Col>
                  <Col lg={2} className='heading-tab'>
                    <p>Delete</p>
                  </Col>
                  <Col lg={1} className='heading-tab'>
                    <p></p>
                  </Col>
                </Row>
                {
                  mappingList.length > 0
                    ? mappingList.map((list, key) => {
                      return (
                        <Row key={key}>
                          <Col lg={3} className='input-tab'>
                            <Select
                              // value={{ value: list.partNumber, label: list.partNumber }}
                              // value={{ value: list.partNumber, label: `${list.partNumber} - ${list.brand}` }}
                              value={list}
                              onChange={(e) => {
                                handleSelectPart(e, key)
                              }}
                              onInputChange={(e) => {
                                setPartValue(e)
                              }}
                              options={parts}
                              onMenuScrollToBottom={(e) => setPagingParts((prevState) => ({ ...pagingParts, currentPage: prevState.currentPage + 1 }))}
                              classNamePrefix="select2-selection"
                              formatOptionLabel={(part) => (
                                <div>
                                  <span>{part.partNumber}</span> - <span>{part.brand}</span>
                                </div>
                              )}
                            />
                          </Col>
                          <Col lg={2} className='input-tab'>
                            <Select
                              isDisabled={true}
                              // value={{ value: list.customerId, label: list.customerFirstName + ' ' + list.customerLastName }}
                              value={{ value: list.customerId, label: list?.companyName }}
                              onChange={(e) => {
                                handleSelectCustomer(e, key)
                              }}
                              onInputChange={(e) => {
                                setCustomerValue(e)
                              }}
                              onBlur={(e) => setSelectIndex(null)}
                              onFocus={(e) => setSelectIndex(key)}
                              options={customers}
                              classNamePrefix="select2-selection"
                              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            />
                          </Col>
                          <Col lg={2} className='input-tab'>
                            <Select
                              isDisabled={true}
                              value={{ value: list.customerLocationId, label: list?.customerLocation?.locationName }}
                              onChange={(e) => {
                                handleSelectLocation(e, key)
                              }}
                              options={customerLocations}
                              classNamePrefix="select2-selection"
                              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            />
                          </Col>
                          <Col lg={2} className='input-tab'>
                            <input type="text" className='text-center input-sell-price' value={list.value} onChange={(e) => handleInputPrice(e, key, list)} />
                          </Col>
                          <Col lg={2} className='input-tab'>
                            <img src={delIcon} style={{ cursor: 'pointer' }} onClick={(e, myID = list, k = key) => accountID(k, myID)} />
                          </Col>
                          <Col lg={1} className='input-tab d-flex justify-content-center align-items-center'>
                            {
                              list.update && (
                                <div className='mx-1'>
                                  <i onClick={() => updateMapping(key, true)} className='fas fa-check-circle input-icon-tick mr-1'></i>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <i onClick={() => updateMapping(key, false)} className='fas fa-times-circle input-icon-cross'></i>
                                </div>
                              )
                            }
                          </Col>
                        </Row>
                      )
                    })
                    : <Row> <Col lg={12} sm={12} className='input-tab'> {tableMessage} </Col></Row>
                }
                {
                  Math.ceil(paging.totalRecord / paging.recordPerPage) > 1 && <div className='d-flex justify-content-center mt-4'>
                    <Pagination count={Math.ceil(paging.totalRecord / paging.recordPerPage)} page={currentPage} onChange={handlePage} variant="outlined" color="primary" shape="rounded" />
                  </div>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-main-body"
        isOpen={customerPopup}
        toggle={() => setCustomerPopup(false)}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0"><b>Add Account Pricing</b></h5>
          <button
            type="button"
            onClick={() => setCustomerPopup(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div>
            <Row>
            <Col lg="12">
                <div className="mb-3">
                  <Label style={{ fontWeight: 'bold' }}>Customer</Label>
                  <Select
                    value={mappingForm.customer}
                    onChange={(e) => {
                      handleSelectCustomer(e, -1)
                    }}
                    onInputChange={(e) => {
                      setCustomerValue(e)
                    }}
                    onFocus={(e) => setSelectIndex('0')}
                    onBlur={(e) => setSelectIndex(null)}
                    options={customers}
                    classNamePrefix="select2-selection"
                  />
                  <span className='error'>{mappingFormError?.customer}</span>
                </div>
              </Col>
              <Col lg="12">
                <div className="mb-3">
                  <Label style={{ fontWeight: 'bold' }}>Location</Label>
                  <Select
                    value={mappingForm.customerLocationId}
                    onChange={(e) => {
                      handleSelectLocation(e, -1)
                    }}
                    // onInputChange={(e) => {
                    //   setCustomerValue(e)
                    // }}
                    // onFocus={(e) => setSelectIndex('0')}
                    // onBlur={(e) => setSelectIndex(null)}
                    options={customerLocations}
                    isSearchable
                    isDisabled={customerLocations.length === 0}
                  />
                  <span className='error'>{mappingFormError?.customerLocationId}</span>
                </div>
              </Col>
              <Col lg="12">
                <div className="mb-3">
                  <Label style={{ fontWeight: 'bold' }}>Part</Label>
                  <Select
                    value={mappingForm.part}
                    onChange={(e) => {
                      handleSelectPart(e, -1)
                    }}
                    onInputChange={(e) => {
                      setPartValue(e)
                    }}
                    onMenuScrollToBottom={(e) => setPagingParts((prevState) => ({ ...pagingParts, currentPage: prevState.currentPage + 1 }))}
                    options={parts}
                    classNamePrefix="select2-selection"
                    formatOptionLabel={(part) => (
                      <div>
                        <span>{part.partNumber}</span> - <span>{part.brand}</span>
                      </div>
                    )}
                  />
                  <span className='error'>{mappingFormError?.part}</span>
                </div>
              </Col>
              <Col lg="12">
                <div className="mb-3">
                  <Label style={{ fontWeight: 'bold' }}>Price</Label>
                  <Input
                    value={mappingForm.price ? mappingForm.price : ''}
                    type="text"
                    className="colorpicker-default"
                    onChange={(e) => handleInputPrice(e, -1)}
                  />
                  <span className='error'>{mappingFormError?.price}</span>
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-btn-div">
            <button
              className="warehouse-btn-yes"
              onClick={() => addAccountPricing()}
            >
              Yes
            </button>
            <button
              className="warehouse-btn-no"
              onClick={() => setCustomerPopup(false)}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        className="modal-main-body"
        isOpen={deletePopup}
        toggle={() => setDeletePopup(false)}
        centered
      >
        <div className="modal-header">
          {/* <h5 className="modal-title mt-0"><b>Add Account Pricing</b></h5> */}
          <button
            type="button"
            onClick={() => setDeletePopup(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div>
            <Row>
              <Col lg="12">
                <div className="mb-3">
                  <h5>Are you sure you want to Delete Account Pricing ? </h5>
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-btn-div">
            <button
              className="warehouse-btn-yes"
              onClick={() => priceDelete()}
            >
              Yes
            </button>
            <button
              className="warehouse-btn-no"
              onClick={() => setDeletePopup(false)}
            // onClick={() => closePopup()}
            // onClick={() => setDeletePopup(false)}
            >
              No
            </button>
          </div>
        </div>

      </Modal>
    </div>
  )
}

export default AccountPricing
