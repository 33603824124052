/* eslint-disable no-new */
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

// //Import Scrollbar
import SimpleBar from 'simplebar-react'

// MetisMenu
import MetisMenu from 'metismenujs'
import { withRouter, Link } from 'react-router-dom'

// i18n

// menu icons
import WdIcon from '../../assets/images/sidebar/icon_wd.svg'
import DiscountIcon from '../../assets/images/sidebar/icon_discount.svg'
import ContentIcon from '../../assets/images/sidebar/icon_content.svg'
import { ReactComponent as DashboardIcon } from '../../assets/images/sidebar/icon_dashboard.svg'
import { ReactComponent as OrdersIcon } from '../../assets/images/sidebar/icon_order.svg'
import { ReactComponent as ProductsIcon } from '../../assets/images/sidebar/icon_product.svg'
import { ReactComponent as CustomersIcon } from '../../assets/images/sidebar/icon_customer.svg'
import { ReactComponent as StaffIcon } from '../../assets/images/sidebar/icon_staff.svg'
import { ReactComponent as ReportsIcon } from '../../assets/images/sidebar/icon_report.svg'
import { ReactComponent as InvoiceIcon } from '../../assets/images/sidebar/icon-invoicing.svg'
import { ReactComponent as CategoryMappingIcon } from '../../assets/images/sidebar/icon-category-mapping.svg'
import { Collapse } from 'reactstrap'

const SidebarContent = props => {
  const ref = useRef()
  const [allowedModules, setAllowedModules] = useState([])
  const [show, setShow] = useState(false)
  const [reports, setReports] = useState(false)
  const currentPath = window.location.pathname.split('/')
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu('#side-menu')
      let matchingMenuItem = null
      const ul = document.getElementById('side-menu')
      const items = ul.getElementsByTagName('a')
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    if (localStorage.authUser) {
      const userObj = JSON.parse(localStorage.authUser)
      setAllowedModules(userObj.allowedModules)
    }
  }, [])

  useEffect(() => {
    ref.current.recalculate()
  })

  const inventoryHandle = (e) => {
    e.preventDefault()
    setShow(prevState => (!prevState))
  }

  const reportsHandle = (e) => {
    e.preventDefault()
    setReports(prevState => (!prevState))
  }

  function scrollElement (item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown (item) {
    item.classList.add('active')
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show')
    }

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show') // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') // a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add('mm-show') // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add('mm-show') // li
              parent5.childNodes[0].classList.add('mm-active') // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }
  const userObj = JSON.parse(localStorage.authUser)

  return (
    <React.Fragment>
      <SimpleBar className={(userObj.roleId === 1 || userObj.roleId === 2) ? 'h-100' : 'h-100 subadmin' } ref={ref}>
        <div id="sidebar-menu" className="sidebar-menu-style">
        { (userObj.roleId === 1 || userObj.roleId === 2)
          ? <ul className="metismenu list-unstyled" id="side-menu">
            {
              allowedModules.findIndex(x => x.permission === 'Dashboard' && x.isAccessible === 1) !== -1
                ? <li className={currentPath.includes('dashboard') ? 'mm-active' : ''}>
                  <Link to="/dashboard" className={currentPath.includes('/dashboard') ? 'active' : ''}>
                    <i className="icon g-fill g-stroke icon-dash"><DashboardIcon /></i>
                    <span>{('Dashboard')}</span>
                  </Link>
                </li>
                : ''
            }
            {
              allowedModules.findIndex(x => (x.permission === 'Order Management' || x.permission === 'Orders') && x.isAccessible === 1) !== -1
                ? <li className={currentPath.includes('orders') ? 'mm-active' : ''}>
                  <Link to="/orders">
                    <i className="icon g-fill g-stroke"><OrdersIcon /></i>
                    <span>{('Orders')}</span>
                  </Link>
                </li>
                : ''
            }
            {
              allowedModules.findIndex(x => x.permission === 'Products' && x.isAccessible === 1) !== -1
                ? <li className={currentPath.includes('products') ? 'mm-active' : ''}>
                  <Link to="/products">
                    <i className="icon g-fill g-stroke"><ProductsIcon /></i>
                    <span>{('Products')}</span>
                  </Link>
                </li>
                : ''
            }
               <li>
              <Link to="/product-management" className="">
                <i className="icon g-fill g-stroke"><ProductsIcon /></i>
                <span>{('Product Management')}</span>
              </Link>
            </li>
            <li>
              <Link to="/shipping-management" className="">
                <i className="icon g-fill g-stroke"><img src={WdIcon} alt="" /></i>
                <span>{('Shipping Management')}</span>
              </Link>
            </li>
            {
              allowedModules.findIndex(x => x.permission === 'Customers' && x.isAccessible === 1) !== -1
                ? <li className={currentPath.includes('customer') ? 'mm-active' : ''}>
                  <Link to="/customer" className={currentPath.includes('customer') ? 'active' : ''}>
                    <i className="icon g-fill g-stroke"><CustomersIcon /></i>
                    <span>{('Customers')}</span>
                  </Link>
                </li>
                : ''
            }
            {
              allowedModules.findIndex(x => x.permission === 'QLSStaff' && x.isAccessible === 1) !== -1
                ? <li className={currentPath.includes('staff-management') ? 'mm-active' : ''}>
                  <Link to="/staff-management" className={currentPath.includes('staff-management') ? 'active' : ''}>
                    <i className="icon g-fill g-stroke"><StaffIcon /></i>
                    <span>{('Staff Management')}</span>
                  </Link>
                </li>
                : ''
            }
            {
              allowedModules.findIndex(x => (x.permission === 'WD' || x.permission === 'Warehouse Management') && x.isAccessible === 1) !== -1
                ? <li className={currentPath.includes('wd-management') ? 'mm-active' : ''}>
                  <Link to="/wd-management" className={currentPath.includes('wd-management') ? 'active' : ''}>
                    <i className="icon g-fill g-stroke icon-wd"><img src={WdIcon} alt="" /></i>
                    <span>{('WD Management')}</span>
                  </Link>
                </li>
                : ''
            }
                <li>
                <Link to="/" className="has-arrow">
                <i className="icon g-fill g-stroke"><ReportsIcon /></i>
                    <span>{('Reports')}</span>
                  </Link>
                <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/reports-sales">
                        {('Sales Report')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/reports-products">
                        {('Sales by Product Type')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/reports-order-fill">
                        {('Order Fill Report')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/reports-order-open">
                        {('Open Order Report')}
                      </Link>
                    </li>
                    <li>
                    <Link to="/reports-customer">
                        {('New Customer Report')}
                      </Link>
                    </li>
                  </ul>
              </li>
            <li>
              <Link to="/invoicing" className="">
                <i className="icon g-fill g-stroke"><InvoiceIcon /></i>
                <span>{('Invoicing')}</span>
              </Link>
            </li>
            <li>
              <Link to="/invoices" className="">
                <i className="icon g-fill g-stroke"><InvoiceIcon /></i>
                <span>{('Open Account Invoices')}</span>
              </Link>
            </li>
            {/* {
              allowedModules.findIndex(x => x.permission === 'discounts' && x.isAccessible === 1) !== -1
                ? <li className={currentPath.includes('discount') ? 'mm-active' : ''}>
                  <Link to="/discount" className="">
                <i className="icon g-fill g-stroke"><img src={DiscountIcon} alt="" /></i>
                <span>{('Discounts')}</span>
              </Link>
                </li>
                : ''
            } */}
            <li>
              <Link to="/discount" className="">
                <i className="icon g-fill g-stroke"><img src={DiscountIcon} alt="" /></i>
                <span>{('Discounts')}</span>
              </Link>
            </li>

            <li>
              <Link to="/notifications" className="">
                <i className="icon g-fill g-stroke bx bx-bell"></i>
                <span>{('Notification')}</span>
              </Link>
            </li>

            <li>
              <Link to="/account-pricing" className="">
                <i className="icon g-fill g-stroke"><InvoiceIcon /></i>
                <span>{('National Account Pricing')}</span>
              </Link>
            </li>
            <li>
              <Link to="/category-mapping" className="">
                <i className="icon g-fill g-stroke"><CategoryMappingIcon /></i>
                <span>{('Category Mapping')}</span>
              </Link>
            </li>

            <li>
              <Link to="/" className="has-arrow">
                <i className="icon g-fill g-stroke"><img src={ContentIcon} alt="" /></i>
                <span>{('Content')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/home-page">
                      {('Homepage')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/knowledge-base">
                      {('Knowledge Database')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/policies">
                      {('Policies')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/global-settings">
                      {('Global Settings')}
                    </Link>
                  </li>
                  <li>
                  <Link to="/services">
                      {('Service Opportunities')}
                    </Link>
                  </li>
                  <li>
                  <Link to="/industry-links">
                      {('Industry Links')}
                    </Link>
                  </li>
                  <li>
                  <Link to="/faq">
                      {('FAQs')}
                    </Link>
                  </li>
                  <li>
                  <Link to="/technical-bulletins">
                      {('Technical Bulletins')}
                    </Link>
                  </li>
                  <li>
                  <Link to="/contact-us">
                      {('Contact Us')}
                    </Link>
                  </li>
                </ul>
            </li>
            <li>
              <Link to="/sds" className="">
              <i className="icon g-fill g-stroke"><img src={ContentIcon} alt="" /></i>
                <span>{('SDS & Product Info')}</span>
              </Link>
            </li>
          </ul>
          : <ul className="metismenu list-unstyled" id="side-menu">
            {
              allowedModules.findIndex(x => x.permission === 'Dashboard' && x.isAccessible === 1) !== -1
                ? <li className="sidebar--li">
                  <Link to="/dashboard" className={currentPath.includes('dashboard') ? 'active' : ''}>
                    <i className="icon g-fill g-stroke icon-dash"><DashboardIcon /></i>
                    <span style={{ color: 'white' }}>{('Dashboard')}</span>
                  </Link>
                </li>
                : ''
            }
            {
              allowedModules.findIndex(x => (x.permission === 'Order Management' || x.permission === 'Orders') && x.isAccessible === 1) !== -1
                ? <li className="sidebar--li">
                  <Link to="/orders" className={currentPath.includes('orders') ? 'active' : ''}>
                    <i className="icon g-fill g-stroke"><OrdersIcon /></i>
                    <span style={{ color: 'white' }}>{('Orders')}</span>
                  </Link>
                </li>
                : ''
            }
            {
              allowedModules.findIndex(x => (x.permission === 'Products') && x.isAccessible === 1) !== -1
                ? <li className="sidebar--li">
                  <Link to="/products" className={currentPath.includes('products') ? 'active' : ''}>
                    <i className="icon g-fill g-stroke"><ProductsIcon /></i>
                    <span style={{ color: 'white' }}>{('Products')}</span>
                  </Link>
                </li>
                : ''
            }
            {
              allowedModules.findIndex(x => (x.permission === 'Inventory') && x.isAccessible === 1) !== -1
                // ? <li className="sidebar--li">
                //   <Link to="/" className="">
                //     {/* <i className="icon g-fill g-stroke"><OrdersIcon /></i> */}
                //     <i className="fas fa-cubes" style={{ color: 'white' }} />
                //     <span style={{ color: 'white' }}>{('Inventory')}</span>
                //   </Link>
                // </li>
                ? <li>
              <a className="has-arrow className sidebar--li" onClick={inventoryHandle} >
                <i className="icon g-fill g-stroke"><img src={ContentIcon} alt="" /></i>
                <span className='text-white'>{('Inventory')}</span>
              </a>
              <Collapse isOpen={currentPath.includes('inventory') || currentPath.includes('inventory-parts') ? true : show}>
              <ul className="sub-menu inventory-menu">
                  <li>
                    <Link to="/inventory" className={currentPath.includes('inventory') ? 'active text-white' : 'text-white'}>
                      {('Inventory')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/inventory-parts" className={currentPath.includes('inventory-parts') ? 'active text-white' : 'text-white'}>
                      {(' Inventory by Part Number')}
                    </Link>
                  </li>
                </ul>
            </Collapse>
            </li>

                : ''
            }
            {
              allowedModules.findIndex(x => (x.permission === 'Reports') && x.isAccessible === 1) !== -1
                ? <li>
                  <a className="has-arrow className sidebar--li" onClick={reportsHandle} >
                <i className="icon g-fill g-stroke"><ReportsIcon/></i>
                <span className='text-white'>{('Reports')}</span>
              </a>
              <Collapse isOpen={currentPath.includes('reports-sales') || currentPath.includes('reports-products') || currentPath.includes('reports-order-fill') ||
              currentPath.includes('reports-order-open') || currentPath.includes('reports-customer')
                ? true
                : reports}>
              <ul className="sub-menu inventory-menu">
                    <li>
                      <Link to="/reports-sales" className={currentPath.includes('reports-sales') ? 'active text-white' : 'text-white'}>
                        {('Sales Report')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/reports-products" className={currentPath.includes('reports-products') ? 'active text-white' : 'text-white'}>
                        {('Sales by Product Type')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/reports-order-fill" className={currentPath.includes('reports-order-fill') ? 'active text-white' : 'text-white'}>
                        {('Order Fill Report')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/reports-order-open" className={currentPath.includes('reports-order-open') ? 'active text-white' : 'text-white'}>
                        {('Open Order Report')}
                      </Link>
                    </li>
                    <li>
                    <Link to="/reports-customer" className={currentPath.includes('reports-customer') ? 'active text-white' : 'text-white'}>
                        {('New Customer Report')}
                      </Link>
                    </li>
                  </ul>
            </Collapse>
              </li>
                : ''
            }
            {
              allowedModules.findIndex(x => (x.permission === 'Customers') && x.isAccessible === 1) !== -1
                ? <li className="sidebar--li">
                  <Link to="/customer" className="">
                    <i className="icon g-fill g-stroke"><CustomersIcon /></i>
                    <span style={{ color: 'white' }}>{('Customers')}</span>
                  </Link>
                </li>
                : ''
            }

            {
              allowedModules.findIndex(x => x.permission === 'WDStaff' && x.isAccessible === 1) !== -1
                ? <li className="sidebar--li">
                  <Link to="/staff-management" className={currentPath.includes('staff-management') ? 'active' : ''}>
                    <i className="icon g-fill g-stroke">< StaffIcon/></i>
                    <span style={{ color: 'white' }}>{('Staff Management')}</span>
                  </Link>
                </li>
                : ''
            }
            {
              allowedModules.findIndex(x => (x.permission === 'Warehouse Management') && x.isAccessible === 1) !== -1
                ? <li className="sidebar--li">
                <Link to="/warehouse-management" className={currentPath.includes('warehouse-management') ? 'active' : ''}>
                <i className="mdi mdi-warehouse" style={{ color: 'white' }} ></i>
                <span style={{ color: 'white' }}>{('Warehouse Management ')}</span>
                </Link>
              </li>
                : ''
            }
            {
              allowedModules.findIndex(x => (x.permission === 'Invoicing') && x.isAccessible === 1) !== -1
                ? <li className="sidebar--li">
                  <Link to="/invoicing" className="">
                    <i className="icon g-fill g-stroke" ><InvoiceIcon /></i>
                    <span style={{ color: 'white' }}>{('Invoicing')}</span>
                  </Link>
                </li>
                : ''
            }
            {/* {
              allowedModules.findIndex(x => x.permission === 'Invoicing' && x.isAccessible === 1) !== -1
                ? <li>
                <Link to="/" className="">
                  <i className="icon g-fill g-stroke"><InvoiceIcon /></i>
                  <span>{('Invoicing')}</span>
                </Link>
              </li>
                : ''
            }
            {
              allowedModules.findIndex(x => x.permission === 'Reports' && x.isAccessible === 1) !== -1
                ? <li>
                  <Link to="/" className="">
                    <i className="icon g-fill g-stroke"><ReportsIcon /></i>
                    <span>{('Reports')}</span>
                  </Link>
                </li>
                : ''
            } */}
          </ul>
        }
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(SidebarContent)
